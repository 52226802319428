import BaseModel from '@models/Base'
import ConversationMessageModel from '@models/ConversationMessage'
import { WindowVariables } from '@utils'

export default class Conversation extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'name',
        'internal_phone_number',
        'external_phone_number',
        'last_message_at',
        'last_system_message_sent',
        'last_external_message_received_at',
        'last_human_message_at',
        'created_at',
        'updated_at',
        'origin_type',
        'lead_id',
        'external_conversation_type',
        'external_conversation_id',
      ],
      dateKeys: [
        'last_message_at',
        'last_system_message_sent',
        'last_external_message_received_at',
        'last_human_message_at',
        'created_at',
        'updated_at',
        'was_lead_at',
      ],
      relationshipMap: {
        conversation_messages: {
          model: ConversationMessageModel,
          collection: true,
        },
      },
    })
  }

  get isSms () {
    return !this.external_conversation_type
  }

  get isNonSms () {
    return !!this.external_conversation_type
  }

  get nonSmsType () {
    return {
      google_business_messages: 'Google Business Messages',
    }[this.external_conversation_type]
  }

  get getOrgPhoneNumberName () {
    const pn = this.getOrgPhoneNumber
    return pn && pn.name
  }

  get getOrgPhoneNumberShortName () {
    const pn = this.getOrgPhoneNumber
    return pn && pn.short_name
  }

  get getOrgPhoneNumber () {
    return WindowVariables.organizationPhoneNumbers.find(opn => opn.phone_number === this.internal_phone_number)
  }

  get unread () {
    //  https://moment.github.io/luxon/#/math?id=comparing-datetimes
    return this.last_external_message_received_at && +this.last_external_message_received_at === +this.last_message_at
  }
}