import axios from 'axios'
import ConversationModel from '@models/Conversation'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/conversations`

function init (d) {
  return new ConversationModel(d)
}

export default {
  async getByPhoneNumber (phoneNumber) {
    return await axios.get(`${BASE_URL}.json`, {
      params: { phone_number: phoneNumber },
    }).then(resp => resp.data.map(init))
  },
  async getAll () {
    return await axios.get(`${BASE_URL}.json`)
      .then(resp => resp.data.map(init))
  },
  async create (params) {
    return await axios.post(`${BASE_URL}.json`, { ...params })
      .then(resp => init(resp.data))
  },
  async update (params) {
    return await axios.put(`${BASE_URL}/${params.id}.json`, { ...params })
      .then(
        resp => init(resp.data),
        resp => resp
      )
  },
  async destroy (id) {
    return await axios.delete(`${BASE_URL}/${id}.json`)
  }
}