<template>
  <div class="border border-success-color p-0.5 md:p-1">
    <span class="text-sm md:text-base text-secondary">CR:</span>
    <span class="text-base md:text-xl font-bold text-primary">{{ parseInt(close_rate.current * 100) }}%</span>
  </div>
</template>

<script>
export default {
  props: {
    close_rate: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style></style>
