import axios from 'axios'
import TechnicianAlert from '@models/TechnicianAlert'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/technician_alerts`

function init (d) {
  return new TechnicianAlert(d)
}

export default {
  async getAll () {
    return await axios.get(`${BASE_URL}.json`)
      .then(resp => resp.data.map(init))
  },
  async create (params) {
    return await axios.post(`${BASE_URL}.json`, { ...params })
      .then(resp => init(resp.data))
  },
  async acknowledge (id) {
    return await axios.patch(`${BASE_URL}/${id}/acknowledge.json`)
      .then(resp => init(resp.data))
  },
  async destroy (id) {
    return await axios.delete(`${BASE_URL}/${id}.json`) 
  }
}