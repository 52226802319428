<template>
  <div class="w-full">
    <!-- name -->
    <div class="flex justify-between items-center w-full">
      <span class="text-lg md:text-2xl font-bold text-gray-dark">
        ${{
          revenue_goal.lower_bound > 0
            ? intToString(revenue_goal.lower_bound)
            : 0
        }}
      </span>

      <span class="text-base md:text-xl font-bold text-secondary uppercase truncate px-1">{{ name }}</span>

      <span class="text-lg md:text-2xl font-bold text-gray-dark">
        ${{ intToString(revenue_goal.upper_bound) }}
      </span>
    </div>

    <!-- progress bar -->
    <div class="w-full bg-gray-light relative">
      <!-- increment -->
      <div v-for="inc in Array.from(Array(numIncrements).keys())" :key="inc"
        class="absolute top-0 h-full even:bg-gray-light odd:bg-gray-base"
        :style="`left: ${inc * 100 / (revenue_goal.upper_bound / technician_revenue_background_increment)}%; width: ${widthOfIncrementBar}%`"
      ></div>

      <div
        class="text-lg md:text-2xl font-bold text-white lowercase text-right relative"
        :class="salesGoalMet ? 'bg-success-color' : 'bg-primary'"
        :style="`width: ${getWidth(revenue_goal.upper_bound, revenue_goal.current)}`"
      >
        <span :class="flipSalesNumberPosition ? 'inline-block ml-2 transform text-primary relative -right-full' : 'mr-2'">
          ${{ parseInt(revenue_goal.current) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    revenue_goal: {
      type: Object,
      default: null,
    },
    technician_revenue_background_increment: {
      type: Number,
      default: 1000,
    },
  },

  computed: {
    numIncrements () {
      return parseInt(this.revenue_goal.upper_bound / this.technician_revenue_background_increment)
    },
    widthOfIncrementBar () {
      return 100 / (this.revenue_goal.upper_bound / this.technician_revenue_background_increment)
    },
    flipSalesNumberPosition () {
      return this.percentageOfGoalMet < 0.2
    },
    percentageOfGoalMet () {
      return (this.revenue_goal.current - this.revenue_goal.lower_bound) / (this.revenue_goal.upper_bound - this.revenue_goal.lower_bound)
    },
    salesGoalMet () {
      return this.revenue_goal.current >= this.revenue_goal.upper_bound
    },
  },

  methods: {
    intToString (num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K"
      }
      return num
    },

    getWidth (goal, current) {
      if (current < goal) {
        return (current / goal) * 100 + "%"
      } else if (current >= goal) {
        return 100 + "%"
      }
    },
  },
}
</script>

<style></style>
