<template>
  <div class="space-y-2 sm:space-y-4 md:space-y-6">
    <!-- img -->
    <div class="w-3/12 sm:w-2/12 md:w-3/12 flex justify-center items-center">
      <div class="h-16 w-16 sm:h-20 sm:w-20 md:h-32 md:w-32 rounded-full overflow-hidden rounded-full">
        <img :src="data.image_url" alt="" class="w-full h-auto inline m-0" />
      </div>
    </div>

    <!-- details -->
    <div class="w-9/12 sm:w-10/12 md:w-9/12 pl-0 md:pl-4 pr-3 md:pr-8 space-y-1 md:space-y-3">
      <technician-revenue
        :name="data.name"
        :revenue_goal="data.revenue_goal"
        :technician_revenue_background_increment="configuration.technician_revenue_background_increment"
      ></technician-revenue>

      <div class="mt-1 flex items-stretch space-x-2">
        <technician-close-rate
          :close_rate="data.close_rate"
        ></technician-close-rate>

        <div class="flex justify-between items-stretch space-x-2">
          <technician-misc-metric
            v-if="data.average_cycle_time && data.average_cycle_time.current !== null"
            label="Avg Cycle"
            :value="data.average_cycle_time.current"
            :border-color="borderColor"
          ></technician-misc-metric>

          <technician-misc-metric
            v-if="data.part_fcc_rate && data.part_fcc_rate.current !== null"
            label="Part FCC"
            :value="data.part_fcc_rate.current"
            :border-color="borderColor"
            unit="%"
          ></technician-misc-metric>

          <!-- <technician-misc-metric
            :label="label"
            :value="data.qr_magic.current"
            :goal="data.qr_magic.goal"
            :border-color="borderColor"
          ></technician-misc-metric> -->

          <!-- <technician-reviews class="" :reviews="data.reviews"></technician-reviews> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TechnicianCloseRate from "./TechnicianCloseRate.vue"
import TechnicianMiscMetric from "./TechnicianMiscMetric.vue"
import TechnicianRevenue from "./TechnicianRevenue.vue"
import TechnicianReviews from "./TechnicianReviews.vue"
export default {
  components: {
    TechnicianRevenue,
    TechnicianCloseRate,
    TechnicianMiscMetric,
    TechnicianReviews,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      label: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6H8V8H6V6Z" fill="#2E3A59"></path><path d="M3 11V3H11V11H3ZM5 5V9H9V5H5Z" fill="#2E3A59"></path><path d="M13 13H16V15H13V13Z" fill="#2E3A59"></path><path d="M16 15H18V13H21V16H18V17H19V18H21V21H19V19H17V21H13V17H15V19H16V15Z" fill="#2E3A59"></path><path d="M18 6H16V8H18V6Z" fill="#2E3A59"></path><path d="M13 3V11H21V3H13ZM19 5V9H15V5H19Z" fill="#2E3A59"></path><path d="M6 16H8V18H6V16Z" fill="#2E3A59"></path><path d="M3 21V13H11V21H3ZM5 15V19H9V15H5Z" fill="#2E3A59"></path></svg> Magic',
      borderColor: "#11133b",
    }
  },

  methods: {
    intToString(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K"
      }
      return num
    },
  },
}
</script>

<style></style>
