import axios from 'axios'
import ConversationMessageModel from '@models/ConversationMessage'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/conversation_messages`

function init (d) {
  return new ConversationMessageModel(d)
}

export default {
  async getAll () {
    return await axios.get(`${BASE_URL}.json`)
      .then(resp => resp.data.map(init))
  },
  async create (params) {
    return await axios.post(`${BASE_URL}.json`, { ...params })
      .then(resp => init(resp.data))
  },
  async destroy (id) {
    return await axios.delete(`${BASE_URL}/${id}.json`)
  }
}