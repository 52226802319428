<template>
  <div class="relative tech-tracker-app">
    <h1 class="text-2xl mb-4 hidden md:flex md:justify-between md:items-end">
      MRA Orlando Leaderboard
      <span class="text-xs text-gray-400">
        <span v-if="showTracker">Last updated at {{ lastUpdatedAt }}.</span>
        <span v-if="!showTracker">Updating report...</span>
      </span>
    </h1>

    <div v-if="showTracker" class="flex justify-end md:justify-start my-2">
      <div class="md:hidden z-10 inline-flex justify-end shadow-sm rounded-md">
        <div class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700">
          {{ currentReportTitle }}
        </div>
        <span class="-ml-px relative block">
          <button @click="mobileMenuOpen = !mobileMenuOpen" type="button" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500" id="option-menu-button" aria-expanded="true" aria-haspopup="true">
            <span class="sr-only">Open options</span>
            <!-- Heroicon name: solid/chevron-down -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>

          <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div v-if="mobileMenuOpen" class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabindex="-1">
            <div class="py-1" role="none">
              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
              <button @click="requestData('current-week')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-0">
                Current Week
              </button>

              <button @click="requestData('last-week')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Last Week
              </button>

              <button @click="requestData('today')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Today
              </button>

              <button @click="requestData('yesterday')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Yesterday
              </button>

              <button @click="requestData('this-pay-period')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Open Pay Period
              </button>

              <button @click="requestData('last-pay-period')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Closed Pay Period
              </button>

              <button @click="requestData('year-to-date')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Year-to-Date
              </button>

              <button @click="requestData('last-year')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
                Last Year
              </button>
            </div>
          </div>
        </span>
      </div>

      <div class="hidden md:flex items-center">
        <div class="relative z-0 inline-flex shadow-sm rounded-md mr-5">
          <button
            @click="requestData('current-week')" type="button" :class="currentReportType === 'current-week' ? currentButtonClasses : deselectedButtonClasses"
            class="relative inline-flex items-center px-2 py-1 rounded-l-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Current Week</button>
          <button
            @click="requestData('last-week')" type="button" :class="currentReportType === 'last-week' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Last Week</button>
          <button
            @click="requestData('today')" type="button" :class="currentReportType === 'today' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Today</button>
          <button
            @click="requestData('yesterday')" type="button" :class="currentReportType === 'yesterday' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Yesterday</button>
          <button
            @click="requestData('this-pay-period')" type="button" :class="currentReportType === 'this-pay-period' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Open Pay Period</button>
          <button
            @click="requestData('last-pay-period')" type="button" :class="currentReportType === 'last-pay-period' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Closed Pay Period</button>
          <button
            @click="requestData('year-to-date')" type="button" :class="currentReportType === 'year-to-date' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Year-to-Date</button>
          <button
            @click="requestData('last-year')" type="button" :class="currentReportType === 'last-year' ? currentButtonClasses : deselectedButtonClasses"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
          >Last Year</button>
        </div>
      </div>

      <div class="hidden md:flex items-center">
        <span class="mr-1">Force Refresh</span>
        <button type="button" @click="forceReports = !forceReports" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false">
          <span class="sr-only">Force Refresh</span>
          <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md"></span>
          <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
          <span aria-hidden="true" :class="forceReports ? 'bg-mra-primary' : 'bg-gray-200'" class="pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="forceReports ? 'translate-x-5' :'translate-x-0'" class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"></span>
        </button>
      </div>
    </div>

    <div v-if="loading" class="text-lg mt-15">
      <svg class="animate-spin my-0 mx-auto w-24 h-24 text-mra-orange-light" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M352.57 128c-28.09 0-54.09 4.52-77.06 12.86l12.41-123.11C289 7.31 279.81-1.18 269.33.13 189.63 10.13 128 77.64 128 159.43c0 28.09 4.52 54.09 12.86 77.06L17.75 224.08C7.31 223-1.18 232.19.13 242.67c10 79.7 77.51 141.33 159.3 141.33 28.09 0 54.09-4.52 77.06-12.86l-12.41 123.11c-1.05 10.43 8.11 18.93 18.59 17.62 79.7-10 141.33-77.51 141.33-159.3 0-28.09-4.52-54.09-12.86-77.06l123.11 12.41c10.44 1.05 18.93-8.11 17.62-18.59-10-79.7-77.51-141.33-159.3-141.33zM256 288a32 32 0 1 1 32-32 32 32 0 0 1-32 32z" class=""></path>
      </svg>
    </div>

    <TechTracker v-if="showTracker" :d="data" />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import TechTracker from '@components/techTracker/TechTracker.vue'
import Reports from '@http/Reports'
import { titleize } from '@utils'

export default {
  name: 'tech-tracker-app',
  components: {
    TechTracker,
  },
  data () {
    return {
      data: null,
      loading: false,
      forceReports: false,
      currentReportType: null,
      currentReportTitle: null,
      currentButtonClasses: 'bg-mra-primary text-white focus:ring-blue-500 focus:border-blue-500',
      deselectedButtonClasses: 'text-gray-700 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-blue-500 focus:border-blue-500',
      currentMobileButtonClasses: 'bg-gray-100 text-gray-900',
      deselectedMobileButtonClasses: 'text-gray-700',
      mobileMenuOpen: false,
    }
  },
  created () {
    this.requestData('current-week')
  },
  computed: {
    showTracker () {
      return !this.loading && this.data
    },
    lastUpdatedAt () {
      return this.showTracker && DateTime.fromISO(this.data.updated_at).toLocaleString(DateTime.DATETIME_MED)
    },
  },
  methods: {
    requestData (reportType) {
      this.mobileMenuOpen = false
      this.loading = true
      return Reports.techTrackerReport({
        reportType: reportType, force: this.forceReports,
      }).then(res => {
        this.data = res
        this.currentReportType = res.report_type
        this.currentReportTitle = titleize(this.currentReportType)
        this.loading = false
      })
    },
  },
}
</script>