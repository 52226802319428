/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'
import VueRouter from 'vue-router'
import * as mobx from 'mobx'
import Movue from 'movue'

import vmodal from 'vue-js-modal'
// import VueTabs from 'vue-nav-tabs/dist/vue-tabs.js'
// import 'vue-nav-tabs/themes/vue-tabs.css'
// import TreeView from 'vue-json-tree-view'
// import 'font-awesome/css/font-awesome.min.css'
// import 'vue-multiselect/dist/vue-multiselect.min.css'

// import Chart from 'chart.js'
// import 'chartjs-plugin-colorschemes'

// Chart.plugins.register({
//   colorschemes: {
//     scheme: 'office.Berlin6',
//   },
// })

Vue.use(VueRouter)
Vue.use(Movue, mobx)
Vue.use(vmodal, { dialog: true })


Vue.config.devtools = true
// Vue.use(VueTabs)
// Vue.use(TreeView)

import ManageTechnicianAlerts from '@components/routes/ManageTechnicianAlerts.vue'
import ConversationsApp from '../conversations_app.vue'
import TechTrackerApp from '@components/routes/TechTrackerApp.vue'


const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'manage-technician-alerts', path: '/utility/technician_alerts/manage', component: ManageTechnicianAlerts },
    { name: 'view-technician-alerts', path: '/utility/technician_alerts', component: ManageTechnicianAlerts },
    { name: 'conversations', path: '/utility/conversations', component: ConversationsApp },
    { name: 'tech-tracker', path: '/reports/tech_tracker', component: TechTrackerApp },
  ],
})

document.addEventListener('turbolinks:load', () => {
  const deregRef = setInterval(function () {
    if (document.getElementById('vue-spa')) {
      new Vue({
        el: '#vue-spa',
        template: '<router-view></router-view>',
        router,
      })
      clearInterval(deregRef)
    }
  }, 50)
})
