import axios from 'axios'
import User from '@models/User'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/users`

function init (d) {
  return new User(d)
}

export default {
  async getById (id) {
    return await axios.get(`${BASE_URL}/${id}.json`)
      .then(resp => init(resp.data))
  },
  async getAll (params) {
    return await axios.get(`${BASE_URL}.json`, { params })
      .then(resp => resp.data.map(init))
  },
}