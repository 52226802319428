<template>
  <div class="flex flex-row w-full border-2 border-gray-200">
    <div class="sidebar flex flew-col w-max w-max" style="height: 600px;flex-direction:column;">
      <div class="sidebar-header w-full flex flex-row">
        <button class="p-4 bg-orange-500 text-white w-full" @click="switchToNewMode">Send New Message</button>
      </div>

      <div class="conversations-list w-64 flex flex-col flex border-r-2 border-solid border-gray-400 bg-white overflow-y-scroll">
        <div
          class="conversation p-4 border-gray-400 border-solid border-b-2 flex flex-col cursor-pointer relative"
          v-for="conv in conversations"
          :key="conv.id"
          @click="selectConversation(conv)"
        >
          <div class="absolute left-0 top-0 h-full w-1 bg-orange-500" v-if="conv.unread"></div>
          <div class="name font-semibold">
            <span class="text-xs text-gray-400 pr-2" v-if="conv.isSms">{{ conv.getOrgPhoneNumberShortName }}</span>
            {{conv.name}}
            <span class="text-xs text-gray-400">
              <template v-if="conv.isSms">{{ conv.external_phone_number.substring(2) }}</template>
              <template v-if="conv.isNonSms">{{ conv.nonSmsType }}</template>
            </span>
          </div>
          <div class="relative-time font-thin text-xs">{{conv.conversation_messages[0].messaged_at.toRelative({ base: luxonNow, units: ['days', 'hours', 'minutes']}) }}</div>
          <div class="last-message text-sm">{{conv.conversation_messages[0].lastSentDisplayName}}) {{ conv.conversation_messages[0].message_body ? conv.conversation_messages[0].message_body.substring(0, 30) : 'ERROR: Empty Message' }}</div>
        </div>
      </div>
    </div>

    <div class="conversation-messages relative flex-1 flex-col overflow-hidden">
      <div class="absolute top-0 w-1/2 h-full bg-gray-200 p-4 transition-all conversation-info-bar" v-if="selectedConversation && replyMode" :class="showInfoBar ? 'right-0' : '-right-full'">
        <svg v-if="showInfoBar" @click="showInfoBar = false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute right-4 top-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
        </svg>
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Smartware Customer Record
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500" v-if="!smartwareConvoSearchResults || !smartwareConvoSearchResults.is_customer">
            No Smartware Customer Found.
          </p>
        </div>

        <div class="border-t border-gray-200 px-4 py-5 sm:p-0" v-if="smartwareConvoSearchResults && smartwareConvoSearchResults.is_customer">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt class="text-sm font-medium text-gray-500">
                Smartware Customer
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ smartwareConvoSearchResults.customer_name }} #{{ smartwareConvoSearchResults.sw_customer_id }} (Created {{ smartwareConvoSearchResults.customer_created_at && smartwareConvoSearchResults.customer_created_at.toLocaleString({
                  year: "2-digit",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }) }})
              </dd>
            </div>

            <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt class="text-sm font-medium text-gray-500">
                Total Work Orders
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ smartwareConvoSearchResults.num_work_orders }} ({{ smartwareConvoSearchResults.work_orders_total | currency }})
              </dd>
            </div>

            <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt class="text-sm font-medium text-gray-500">
                Open Work Orders
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ smartwareConvoSearchResults.open_work_orders_summary }}
              </dd>
            </div>

            <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt class="text-sm font-medium text-gray-500">
                Last Work Order
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <template v-if="smartwareConvoSearchResults.most_recent_work_order_id">
                  <a class="text-blue-600 hover:text-blue-400" :href="`https://productivity-tools.webbintegrated.com/work_orders/${smartwareConvoSearchResults.most_recent_work_order_id}`" target="_blank">{{ smartwareConvoSearchResults.most_recent_work_order_summary }}</a>
                </template>
                <template v-else>
                  {{ smartwareConvoSearchResults.most_recent_work_order_summary }}
                </template>
              </dd>
            </div>

          </dl>
        </div>

        <div v-if="selectedConversationPhoneCalls">
          <table class="table-less-column-padding table-text-xs">
            <thead>
              <tr>
                <th>Time</th>
                <th>Type</th>
                <th>User</th>
                <th>Duration</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="call in selectedConversationPhoneCalls" :key="call.id">
                <td>{{
                    call.start_time.toLocaleString({
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}</td>
                <td>{{ call.call_type_pretty }}</td>
                <td>{{ call.phone_user }}</td>
                <td>{{ call.call_length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template v-if="newMode">
        <div class="conversation-header flex flex-row w-full w-8 justify-between bg-blue-100">
          <select v-model="fromPhoneNumber">
            <option v-for="option in organizationPhoneNumbers" :value="option.phone_number" :key="option.id">{{ option.name }}</option>
          </select>
          <div>
            <input type="text" v-model="newPhoneNumber" placeholder="+15555555555"/>
            <button class="btn bg-mra-primary text-xs px-2 py-2" @click="searchForPhoneConversation">Fetch Conversation</button>
          </div>
          <input type="text" class="p-2 bg-gray-100 rounded" placeholder="Conversation Name" v-model="newConvoName" />

        </div>

        <div class="white-bg w-full flex flex-col">
          <div class="flex flex-row">
            <select v-model="selectedTemplate" @change="setMessageTemplate" class="my-4">
              <option value="" selected>Select a Template (Optional)</option>
              <option v-for="option in messageTemplates" :value="option" :key="option.name">{{ option.name }}</option>
            </select>
            <select v-model="messageMedia" class="my-4">
              <option value="" selected>Select Media to Attach</option>
              <option v-for="option in availableMedia" :value="option" :key="option.name">{{ option.name }}</option>
            </select>
            <!-- <div class="media-area w-full mt-6 pl-4">
              {{ messageMedia ? "Attached: " + messageMedia.name : 'No Media Selected' }}
            </div> -->
          </div>
          <div
            class="flex flex-row border-gray items-center w-full border rounded-3xl"
          >
            <textarea
              v-model="messageText"
              class="border rounded-2xl border-transparent w-full focus:outline-none text-sm flex items-center" rows="5"
              placeholder="Type your message...."
            />
            <button
              id="self"
              @click="sendNewMessage"
              class="flex items-center justify-center h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-300 text-indigo-800 text-white focus:outline-none"
            >
              <svg
                class="w-5 h-5 transform rotate-90 -mr-px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </template>
      <template v-if="selectedConversation && replyMode">
        <div class="conversation-header flex flex-row w-full w-8 justify-between bg-blue-600 p-4">
          <div class="conversation-name">
            <template v-if="selectedConversation.name">
              {{ selectedConversation.name }}
              <span v-if="selectedConversation.isSms" class="text-sm text-gray-100">{{selectedConversation.external_phone_number }}</span>
              <span v-if="selectedConversation.isNonSms" class="text-sm text-gray-100">{{selectedConversation.nonSmsType }}</span>
            </template>
            <template v-else>
              {{ selectedConversation.external_phone_number }}
            </template>

            <template v-if="selectedConversation.origin_type">
              &nbsp;&nbsp;({{ selectedConversation.origin_type }})
            </template>

            <div class="last-messaged-at text-gray-200 text-sm font-thin">
              Last Messaged: {{ selectedConversation.conversation_messages[0].messaged_at.toLocaleString({
                year: "2-digit",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }) }}</div>
          </div>
          <div class="name-change-wrapper flex flex-row">
            <input type="text" class="p-2 bg-gray-100 rounded" placeholder="Conversation Name" v-model="selectedConversation.name" />
            <button @click="updateConversationName" class="bg-gray-100 rounded p-2">Save</button>
          </div>

          <div class="convo-icons">
            <svg v-if="!showInfoBar" @click="showInfoBar = true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
            </svg>

          </div>

        </div>
        <div class="chat-wrap flex flex-col mt-2 flex-col-reverse overflow-y-scroll space-y-3 pb-3 h-96">
          <template v-for="obj in messageObjects">
            <div :key="obj.sort" v-if="obj.type === 'ConversationMessage'"
              :class="{
                'other break-all mt-2  ml-5 rounded-bl-none float-none bg-gray-300 mr-auto rounded-2xl p-2':
                  obj.obj.isExternal,
                'ml-auto break-all mt-2 mb-1 p-2 rounded-br-none bg-blue-500 rounded-2xl text-white text-left mr-5':
                  obj.obj.isInternal,
              }"
              class="flex flex-col "
              style="max-width:90%;"
            >
              <div class="timestamp text-sm">
                <span class="text-gray-400">
                  {{
                    obj.obj.messaged_at.toLocaleString({
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                </span>
                <span class="ml-1" :class="{ 'text-gray-400': obj.obj.isExternal, 'text-gray-200': obj.obj.isInternal }">{{ obj.obj.sent_by_user_name }}</span>
              </div>

              <div class="message-body" v-if="obj.obj.message_body !== '--- No message ---'">{{ obj.obj.message_body }}</div>
              <div class="message-attachments">
                <a class="mr-2 text-blue-600" target="_blank" :href="url" v-for="url in obj.obj.attachment_urls" :key="url">
                  <img :src="url" width="400px" />
                </a>
              </div>
            </div>
            <div :key="obj.sort" v-if="obj.type === 'PhoneCall'"
              class="w-full flex justify-center"
            >
              <div class="bg-mra-orange-light w-auto p-3 rounded-lg text-white flex gap-8">
                <div class="flex gap-2" v-if="obj.obj.call_type === 'origination'">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                  </svg>
                  ({{ obj.obj.phone_user }})
                  Dialed In
                </div>
                <div class="flex gap-2" v-else-if="obj.obj.call_type === 'termination'">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                  </svg>
                  ({{ obj.obj.phone_user }})
                  Dialed Out
                </div>
                <div class="flex gap-2" v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  ({{ obj.obj.phone_user }})
                  {{ obj.obj.call_type }}
                </div>
                <span>
                  {{
                    obj.obj.start_time.toLocaleString({
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                </span>
                <span>{{ obj.obj.call_length }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="flex flex-row items-center bottom-0 my-2 w-full">
            <div
              class="ml-2 flex flex-row border-gray items-center w-full border rounded-3xl h-12 px-2"
            >
              <div class="w-full">
                <input
                  type="text"
                  id="message"
                  v-model="messageText"
                  class="border rounded-2xl border-transparent w-full focus:outline-none text-sm h-10 flex items-center"
                  placeholder="Type your message...."
                />
              </div>
              <div class="flex flex-row">
                <button
                  class="focus:outline-none flex items-center justify-center h-10 w-8 hover:text-blue-600 text-blue-400"
                >
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    ></path>
                  </svg>
                </button>
                <button
                  id="capture"
                  class="focus:outline-none flex items-center justify-center h-10 w-8 hover:text-green-600 text-green-400 ml-1 mr-2"
                >
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <button
                id="self"
                @click="sendReply"
                class="flex items-center justify-center h-10 w-10 mr-2 rounded-full bg-gray-200 hover:bg-gray-300 text-indigo-800 text-white focus:outline-none"
              >
                <svg
                  class="w-5 h-5 transform rotate-90 -mr-px"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
      </template>
    </div>
  </div>
</template>

<script>
import ConversationsService from "@services/ConversationsService"
import Notify from '@services/Notify'

import { DateTime, Duration } from 'luxon'
import { WindowVariables } from './utils'

import { sortBy } from 'lodash-es'
import axios from 'axios'

window.DateTime = DateTime

export default {
  name: "conversations-app",
  data() {
    return {
      selectedConversation: null,
      showInfoBar: false,
      messageText: null,
      conversationName: '',
      luxonNow: DateTime.local(),
      smartwareConvoSearchResults: null,
      selectedConversationLead: null,
      selectedConversationPhoneCalls: null,
      newPhoneNumber: '',
      currentMode: 'new',
      fromPhoneNumber: '+14074409016',
      messageMedia: '',
      newConvoName: '',
      selectedTemplate: '',
      organizationPhoneNumbers: WindowVariables.organizationPhoneNumbers.filter(ph => ph.number_type === 'external'),
      availableMedia: WindowVariables.commonMedia,
      templates: WindowVariables.templates,
      phoneSearch: '',
    }
  },
  fromMobx: {
    conversations() {
      return ConversationsService.allConversations
    },
    watchTrigger() {
      return ConversationsService.watchTrigger
    },
  },
  methods: {
    selectConversation(conv) {
      console.log('selectconversation', conv)
      this.selectedConversation = conv
      this.conversationName = conv.name
      this.currentMode = 'reply'
      this.smartwareConvoSearchResults = null
      this.selectedConversationLead = null
      this.selectedConversationPhoneCalls = null

      if (this.selectedConversation.lead_id) {
        axios.get(`/leads/${this.selectedConversation.lead_id}`)
          .then(resp => {
            this.selectedConversationLead = resp.data
          }, resp => {
            this.selectedConversationLead = undefined
          })
      } else {
        this.selectedConversationLead = undefined
      }


      // Short circuit so we don't try to look up phone calls and leads when
      // there is no phone number to look at
      if (this.selectedConversation.isNonSms) {
        return false
      }

      axios.get(`/leads/search_smartware?phone_number=${this.selectedConversation.external_phone_number}`)
        .then(resp => {
          this.smartwareConvoSearchResults = resp.data
          if (this.smartwareConvoSearchResults.customer_created_at) {
            this.smartwareConvoSearchResults.customer_created_at = DateTime.fromISO(this.smartwareConvoSearchResults.customer_created_at)
          }

        }, resp => {
          this.smartwareConvoSearchResults = undefined
        })

      axios.get(`/phone_calls.json?phone_number=${this.selectedConversation.external_phone_number.replace('+', '')}`)
        .then(resp => {
          this.selectedConversationPhoneCalls = resp.data.map(d => {
            d.created_at = DateTime.fromISO(d.created_at)
            d.start_time = DateTime.fromISO(d.start_time)
            const dur = Duration.fromMillis(d.call_duration * 1000).shiftTo('minutes', 'seconds')
            console.log(dur, dur.minutes, dur.seconds)
            d.call_type_pretty = d.call_type === 'origination' ? 'IN' : (d.call_type === 'termination' ? 'OUT' : '')
            d.call_length = `${dur.minutes}:${Math.floor(dur.seconds).toString().padStart(2, '0')}`
            return d
          })
        }, resp => {
          this.selectedConversationPhoneCalls = undefined
        })



    },
    updateConversationName() {
      ConversationsService.updateConversationName(this.selectedConversation.id, this.selectedConversation.name)
    },
    switchToNewMode () {
      this.currentMode = 'new'
    },
    setMessageTemplate () {
      if (this.selectedTemplate) {
        this.messageText = this.selectedTemplate.message_body
        this.messageMedia = this.selectedTemplate.common_media
      } else {
        this.messageText = ''
        this.messageMedia = null
      }
    },
    sendNewMessage () {
      let to_phone_number = this.newPhoneNumber
      if (!this.newPhoneNumber.startsWith('+1')) {
        to_phone_number = "+1" + this.newPhoneNumber.replace(/-/g, '').replace(/\(/, '').replace(/\)/, '').replace(/\s/, '')
      }
      const data = {
        from_phone_number: this.fromPhoneNumber,
        to_phone_number: to_phone_number,
        message_body: this.messageText,
        conversation_id: null,
        ...(this.messageMedia) && { media_url: this.messageMedia.url },
        conversation_name: this.newConvoName,
      }

      console.log('convoData', data)

      ConversationsService.sendMessage(data)
        .then(() => {
          this.messageText = ""
          this.selectedTemplate = null
          // this.fromPhoneNumber = null
          this.newPhoneNumber = ''
          this.newConvoName = ''
          this.currentMode = 'reply'
        })

    },
    sendReply() {
      let payload = {
        from_phone_number: this.selectedConversation.internal_phone_number,
        ...this.messageMedia && { media_url: this.messageMedia.url },
      }
      if (this.selectedConversation.isNonSms) {
        payload = {
          message_sender_type: 'internal',
        }
      }

      payload.conversation_id = this.selectedConversation.id
      payload.message_body = this.messageText

      ConversationsService.sendMessage(payload)
        .then(() => {
          this.messageText = ""
          const conv = this.conversations.find(
            (c) => c.id === this.selectedConversation.id
          )

          if (conv) {
            this.selectConversation(conv)
          }
        })
    },
    searchForPhoneConversation () {
      let to_phone_number = this.newPhoneNumber
      if (!this.newPhoneNumber.startsWith('+1')) {
        to_phone_number = "+1" + this.newPhoneNumber.replace(/-/g, '').replace(/\(/, '').replace(/\)/, '').replace(/\s/, '')
      }
      return ConversationsService.searchByPhoneNumber(to_phone_number)
        .then(conv => {
          console.log('CONV', conv)
          if (conv.length === 0) {
            Notify.error('No conversations found with given phone number')
            return null
          } else if (conv.length > 1) {
            Notify.error('More than 1 conversation returned. Data corruption is likely.')
            return null
          } else {
            this.selectConversation(conv[0])
            this.newPhoneNumber = ''
            return conv[0]
          }
        })
        .catch(resp => {
          Notify.error('Error fetching Conversations by Phone Number.')
          return resp
        })
    }
  },
  watch: {
    watchTrigger(newVal, oldVal) {
      console.log('watch trigger', newVal, oldVal)
      if (this.selectedConversation && newVal !== oldVal) {
        const conv = this.conversations.find(
          (conv) => conv.id === this.selectedConversation.id
        )

        this.selectConversation(conv)
      }
    },
  },
  computed: {
    messageObjects () {
      return sortBy([
        ...this.selectedConversation.conversation_messages.map(obj => {
          return {
            type: 'ConversationMessage',
            sort: -1 * obj.messaged_at.toMillis(),
            obj: obj,
          }
        }),

        ...(this.selectedConversationPhoneCalls || []).map(obj => {
          return {
            type: 'PhoneCall',
            sort: -1 * (new DateTime(obj.start_time)).toMillis(),
            obj,
          }
        })
      ], 'sort')
    },
    replyMode () {
      return this.currentMode === 'reply'
    },
    newMode () {
      return this.currentMode === 'new'
    },
    messageTemplates () {
      return this.templates.map(template => {
        template.common_media = this.availableMedia.find(m => m.id === template.common_media_id)
        return template
      })
    }
  },
  async created() {
    await ConversationsService.load()

    setInterval(() => {
      this.luxonNow = DateTime.local()
    }, 5000)

    Notification.requestPermission()

  },
};
</script>