export const WindowVariables = {
  currentUser: window.railsVars.current_user,
  currentUserRoles: window.railsVars.current_user_roles,
  currentUserIsAdmin: window.railsVars.current_user_is_admin,
  primaryDomain: window.railsVars.primary_domain,
  organizationPhoneNumbers: window.railsVars.organization_phone_numbers,
  notificationDingFilename: window.railsVars.notification_ding_filename,
  templates: window.railsVars.templates,
  commonMedia: window.railsVars.common_media,
  daySummaryResults: window.railsVars.day_summary_results,
  dayAndTechnicianSummaryResults: window.railsVars.day_tech_summary_results,
}

export function titleize (str) {
  return str.toLowerCase()
    .replace('-', ' ')
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
    .join(' ')
}