<template>
  <div class="flex justify-between items-center space-x-2 py-1">
    <div class="text-lg md:text-2xl font-bold text-gray-dark lowercase">
      <span>${{ intToString(revenue_goal.lower_bound) }}</span>
    </div>

    <div class="w-full bg-gray-light relative">
      <!-- Current Revenue -->
      <div
        class="text-base md:text-xl py-1 md:py-0 font-bold text-white text-right leading-none"
        :class="{ 'bg-yellow text-black': goalMet, 'bg-success-color text-white': !goalMet }"
        :style="`width: ${barWidthPercentage}%`"
      >
        <span class="mx-2">${{ goalMet ? revenue_goal.current.toLocaleString('en-US', { maximumFractionDigits: 0 }) : intToString(revenue_goal.current) }}</span>
      </div>

      <!-- Projected Revenue -->
      <div v-if="showProjectedSales"
        class="absolute top-0 h-full w-2 transform translate-x-[-50%] bg-red-600 p-[.07rem] border-b border-t"
        :style="`left: ${projectedLeftPosition}%`"
      >
        <span
          class="inline-block transform -translate-y-6 -translate-x-4 text-xs font-semibold text-red-600"
          >${{ intToString(revenue_goal.projected) }}</span
        >
      </div>
    </div>

    <div class="text-lg md:text-2xl font-bold text-gray-dark">
      <span>${{ intToString(revenue_goal.upper_bound) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    revenue_goal: {
      type: Object,
      default: null,
    },
  },

  computed: {
    showProjectedSales () {
      return !!this.revenue_goal.projected && !this.goalMet && this.revenue_goal.current !== this.revenue_goal.projected && this.revenue_goal.projected < this.revenue_goal.upper_bound
    },
    goalMet () {
      return this.revenue_goal.current >= this.revenue_goal.upper_bound
    },
    barWidthPercentage () {
      if (this.revenue_goal.current < this.revenue_goal.lower_bound) {
        return 0
      } else if (this.revenue_goal.current >= this.revenue_goal.upper_bound) {
        return 100
      } else {
        const numerator = this.revenue_goal.current - this.revenue_goal.lower_bound
        const denominator = this.revenue_goal.upper_bound - this.revenue_goal.lower_bound
        return (numerator / denominator) * 100
      }
    },
    projectedLeftPosition () {
      return Math.min((this.revenue_goal.projected / this.revenue_goal.upper_bound), 1.0) * 100
    }
  },

  methods: {
    intToString(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k"
      }
      return num
    },
  },
}
</script>

<style></style>
