<template>
  <div class="view-technician-alerts">
    <h1 v-if="!embedded" class="text-2xl mb-4 flex justify-between items-end">
      MRA Orlando Technician Alerts
      <span class="text-xs text-gray-400">Refreshing data in {{ refreshingIn }}</span>
    </h1>
    <TechnicianAlertList class="my-3 first:mt-0 last:mb-0"
      :technician="tech"
      :hide-header="embedded"
      v-for="tech in allTechnicians" :key="tech.id"
    />
  </div>
</template>

<script>
import { groupBy } from 'lodash-es'
import { DateTime, Duration } from 'luxon'
import TechnicianAlertService from '@services/TechnicianAlertService'
import TechnicianAlertList from '@components/TechnicianAlertList.vue'

const REFRESH_TIME_MS = 5 * 60 * 1000

export default {
  name: 'technician-alert-app',
  components: {
    TechnicianAlertList,
  },
  props: {
    embedded: {
      type: Boolean,
    },
  },
  data () {
    return {
      now: DateTime.local(),
      lastLoadedAt: DateTime.local(),
    }
  },
  fromMobx: {
    alertsByTechnicianId () {
      return TechnicianAlertService.alertsByTechnicianId
    },
    allTechnicians () {
      return TechnicianAlertService.allTechnicians
    },
    isLoading () {
      return TechnicianAlertService.isLoading
    },
  },
  computed: {
    timeToRefresh () {
      return Duration.fromMillis(
        REFRESH_TIME_MS - this.now.diff(this.lastLoadedAt)
      ).shiftTo('minutes', 'seconds')
    },
    refreshingIn () {
      return this.isLoading
        ? 'Refreshing...'
        : `${this.timeToRefresh.minutes}:${Math.floor(this.timeToRefresh.seconds).toString().padStart(2, '0')}`
    },
  },
  methods: {
    refreshData () {
      TechnicianAlertService.load()
        .then(() => {
          this.lastLoadedAt = DateTime.local()
        })
    }
  },
  watch: {
    timeToRefresh (newVal) {
      if (newVal !== undefined && newVal <= 0) {
        this.refreshData()
      }
    },
  },
  created () {
    this.refreshData()

    if (!this.embedded) {
      setInterval(() => {
        this.now = DateTime.local()
      }, 1000)
    }
  },
}
</script>