import BaseModel from '@models/Base'
import { WindowVariables } from '@utils'

export default class ConversationMessage extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'to_phone_number',
        'from_phone_number',
        'messaged_at',
        'message_body',
        'conversation_id',
        'message_sender_type',
        'created_at',
        'updated_at',
        'sent_by_user_id',
        'sent_by_user',
        'sent_by_user_name',
        'attachment_urls',
        'lead_id',
      ],
      dateKeys: [
        'messaged_at',
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
      },
    })
  }

  get isExternal () {
    return this.message_sender_type === 'external'
  }

  get isInternal () {
    return this.message_sender_type === 'internal'
  }

  get isALeadMessage () {
    return this.lead_id
  }

  get lastSentDisplayName () {
    if (this.sent_by_user_id === 0) {
      return 'Sys'
    } else if (!this.sent_by_user_id) {
      return 'Cust'
    } else {
      return this.sent_by_user && `${this.sent_by_user.first_name[0]}${this.sent_by_user.last_name[0]}` || 'Mra'
    }
  }

  get getOrgPhoneNumberName () {
    const pn = this.getOrgPhoneNumber
    return pn && pn.name
  }

  get getOrgPhoneNumberShortName () {
    const pn = this.getOrgPhoneNumber
    return pn && pn.short_name
  }

  get getOrgPhoneNumber () {
    return WindowVariables.organizationPhoneNumbers.find(opn => opn.phone_number === (this.message_sender_type === 'internal' ? this.from_phone_number : this.to_phone_number))
  }
}