<template>
  <div class="bg-white shadow rounded-lg">
    <div v-if="!hideHeader" class="px-4 py-3 sm:px-6 flex justify-between items-center">
      <div class="text-xl">{{ technician.fullname }}</div>
      <div class="action-buttons">
        <button v-if="canAddAlerts && !showNewForm" class="btn btn-primary" @click="showNewForm = true">Add</button>
        <button v-if="canAddAlerts && showNewForm" class="btn btn-primary" @click="submit">Submit</button>
        <button v-if="canAddAlerts && showNewForm" class="btn btn-danger" @click="showNewForm = false">Cancel</button>
      </div>
    </div>
    <template v-if="isLoading">
      <div class="bg-gray-300 h-28 flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 38 38"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="#fff" stop-opacity="0" offset="0%"/><stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/><stop stop-color="#fff" offset="100%"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></path><circle fill="#fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></circle></g></g></svg>
      </div>
    </template>
    <template v-hide="isLoading">
      <div class="bg-gray-50 px-4 py-5 sm:p-3 sm:px-6 rounded-b-lg">
        <template v-if="!showNewForm">
          <ul v-if="alerts.length > 0" class="divide-y divide-gray-200">
            <TechnicianAlert :alert="alert" v-for="alert in sortedAlerts" :key="alert.id" />
          </ul>
          <div v-else class="text-lg text-gray-400">{{ noNotifications }}</div>
        </template>
        <template v-else>
          <NewTechnicianAlert :technician="technician" @change="handleNewChange" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import TechnicianAlertService from '@services/TechnicianAlertService'
import TechnicianAlert from '@components/TechnicianAlert.vue'
import NewTechnicianAlert from '@components/NewTechnicianAlert.vue'
import { WindowVariables } from '@utils'

export default {
  name: 'technician-alert-list',
  components: {
    TechnicianAlert,
    NewTechnicianAlert,
  },
  props: {
    technician: {
      type: Object,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      newAlertData: {},
      showNewForm: false,
    }
  },
  fromMobx: {
    alerts () {
      return TechnicianAlertService.alertsByTechnicianId[this.technician.id] || []
    },
    isLoading () {
      return TechnicianAlertService.isLoading
    },
  },
  computed: {
    canAddAlerts () {
      return TechnicianAlertService.currentUserIsCsr || TechnicianAlertService.currentUserIsAdmin
    },
    sortedAlerts () {
      return sortBy(this.alerts, ['posted_at', 'created_at'])
    },
    noNotifications () {
      return this.technician.id === WindowVariables.currentUser.id
        ? 'You have no alerts at this time.'
        : 'There are no alerts for this technicians.'
    }
  },
  methods: {
    handleNewChange (evt) {
      this.newAlertData = evt
      this.newAlertData.posted_at.setHours(0, 0, 0, 0)
    },
    submit () {
      TechnicianAlertService.createAlert(this.newAlertData)
        .then(() => { this.showNewForm = false })
    },
  },
}
</script>
