import axios from 'axios'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/reports`

export default {
  async techTrackerReport (opts) {
    return await axios.get(`${BASE_URL}/tech_tracker_report.json`, {
      params: { report_type: opts.reportType, force: opts.force },
    }).then(resp => resp.data)
  },
}