<template>
  <div class="border bg-primary pt-4 pb-2 md:py-4 px-2 md:px-4 relative text-secondary font-bold md:w-full lg:w-50r">
    <div class="absolute top-1 md:top-2 right-2 text-xs md:text-sm">
      {{ dateDisplay }}
    </div>
    <div class="flex justify-between items-center mb-2 md:mb-4">
      <div class="w-3/12">
        <img :src="data.company_logo_url" alt="" />
      </div>

      <div class="w-9/12">
        <h1 class="text-secondary font-bold uppercase text-xl md:text-3xl tracking-wide text-center">
          {{ data.header }}
        </h1>
        <h3 class="font-bold uppercase text-base md:text-2xl tracking-wide text-gray-dark text-center">
          {{ dateByline }}
        </h3>
        <h4 class="uppercase text-base md:text-xl tracking-wide text-gray-dark text-center">
          Team Total: {{ teamTotal }}
        </h4>
      </div>
    </div>

    <revenue-goal :revenue_goal="data.revenue_goal"></revenue-goal>

    <div class="bg-white py-1 sm:p-2 md:p-4">
      <technician-tracker
        class="flex justify-between items-center md:space-x-4 last:mb-2"
        v-for="(tech, i) in data.technicians" :key="i"
        :configuration="data.configuration"
        :data="tech"
      ></technician-tracker>
    </div>
  </div>
</template>

<script>
import RevenueGoal from './RevenueGoal.vue'
import TechnicianTracker from './TechnicianTracker.vue'

export default {
  components: { RevenueGoal, TechnicianTracker },
  props: {
    d: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      data: this.d,
    }
  },
  computed: {
    reportType () {
      return this.data.report_type
    },
    dateByline () {
      if (this.reportType === 'current-week' || this.reportType === 'today' || this.reportType === 'yesterday') {
        return `Week ${this.data.week_current_number} of ${this.data.week_upper_bound} - ${this.getDay(new Date(this.data.end_date))}`
      } else if (this.reportType === 'last-week') {
        return `Week ${this.data.week_current_number} of ${this.data.week_upper_bound}`
      } else if (this.reportType === 'year-to-date') {
        return `Year-to-Date ${this.data.current_year}`
      } else if (this.reportType === 'this-pay-period') {
        const end = DateTime.fromISO(this.data.end_date)
        return `Open until ${end.toLocaleString(DateTime.DATE_SHORT)}`
      } else if (this.reportType === 'last-pay-period') {
        const end = DateTime.fromISO(this.data.end_date)
        return `Closed on ${end.toLocaleString(DateTime.DATE_SHORT)}`
      }
    },
    dateDisplay () {
      if (!this.data.start_date || !this.data.end_date) {
        return ''
      }

      const start = DateTime.fromISO(this.data.start_date)
      const end = DateTime.fromISO(this.data.end_date)
      if (this.reportType === 'current-week' || this.reportType === 'last-week' || this.reportType === 'this-pay-period' || this.reportType === 'last-pay-period') {
        return `${start.toLocaleString(DateTime.DATE_SHORT)} to ${end.toLocaleString(DateTime.DATE_SHORT)}`
      } else if (this.reportType === 'today' || this.reportType === 'yesterday') {
        return start.toLocaleString(DateTime.DATE_SHORT)
      } else {
        return ''
      }
    },
    teamTotal () {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      return formatter.format(
        this.data.technicians.reduce((total, t) => total + t.revenue_goal.current, 0)
      )
    },
  },
  methods: {
    getDay (date) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ]

      // const currentDate =
      //   this.data.current_year + "/" + this.data.current_month + "/" + this.data.current_date

      // const d = new Date(currentDate)
      console.log(date, date.getDay())
      return days[date.getDay()]
    },
  },
}
</script>

<style></style>
