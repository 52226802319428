<template>
  <div class="new-technician-alert flex">
    <div class="flex-1 mr-1">
      <label for="text" class="block text-sm font-medium text-gray-700">Text</label>
      <div class="mt-1">
        <input class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          id="text"
          type="text"
          name="text"
          v-model="text"
        />
      </div>
      <p class="mt-2 text-sm text-gray-500" id="text-description">What does {{ technician.fullname }} need to know?</p>
    </div>
    <div style="min-width:250px;">
      <label for="text" class="block text-sm font-medium text-gray-700">Post Date</label>
      <div class="mt-1">
        <Datepicker v-model="date"
          :disabled-dates="disabledDates"
        />
      </div>
      <p class="mt-2 text-sm text-gray-500" id="text-description">When do they need to know it?</p>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'new-technician-alert',
  components: {
    Datepicker,
  },
  props: {
    technician: {
      type: Object,
      required: true,
    },
  },
  data () {
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return {
      date: today,
      text: '',
      disabledDates: { to: yesterday },
    }
  },
  computed: {
    submissionData () {
      return {
        technician_id: this.technician.id,
        text: this.text,
        posted_at: this.date,
      }
    },
  },
  methods: {
    onChange () {
      this.$emit('change', this.submissionData)
    },
  },
  created () {
    this.onChangeDebounced = debounce(this.onChange, 200)
  },
  watch: {
    submissionData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChange()
      }
    },
  },
}
</script>

<style>

</style>
