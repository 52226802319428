import { action, computed, observable } from 'mobx'
import { findIndex, groupBy, reject, sortBy } from 'lodash-es'
import ConversationModel from '@models/Conversation'
import Conversations from '@http/Conversations'
import Messages from '@http/Messages'
import { WindowVariables } from '@utils'

import consumer from '../channels/consumer'

class ConversationsService {
  @observable _conversations = []
  @observable _conversationsLoading = false

  _promise = null
  @observable _watchTrigger = 0

  constructor () {
    const that = this
    consumer.subscriptions.create("MessagesChannel", {
      connected() {
        console.log('MessagesChannel subscription connected')
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(d) {
        console.log('got some data', d)
        const cm = new ConversationModel(d.data)
        that.insertOrUpdateConversation(cm)
        that._watchTrigger += 1

        const latestMessage = d.data.conversation_messages[0]
        if (latestMessage.message_sender_type === 'external') {
          let prefix = '???'
          if (cm.isSms) {
            const phoneLineNumber = latestMessage.message_sender_type === 'external'
              ? latestMessage.to_phone_number
              : latestMessage.from_phone_number
            const phoneLineObj = WindowVariables.organizationPhoneNumbers.find(ph => ph.phone_number === phoneLineNumber)
            if (phoneLineObj) {
              prefix = phoneLineObj.short_name
            }
          } else {
            prefix = 'Non-Sms'
          }
          const n = new Notification('New Customer Message', { body: `${prefix}: ${d.data.conversation_messages[0].message_body}` })
          const a = (new Audio(`https://forms.mraorlando.com/${WindowVariables.notificationDingFilename}`)).play()
        }

        // Called when there's incoming data on the websocket for this channel
      }
    })
  }

  @computed
  get watchTrigger () {
    return this._watchTrigger
  }

  @action
  insertOrUpdateConversation(conv) {
    const convIdx = this._conversations.findIndex(c => c.id === conv.id)

    if (convIdx > -1) {
      this._conversations[convIdx] = conv
    } else {
      this._conversations.push(conv)
    }
  }

  @action updateConversationName(conversationId, name) {
    Conversations.update({ id: conversationId, name: name })
    .then(conv => {
      this.insertOrUpdateConversation(conv)
      return Promise.resolve(conv)
    }, resp => {
      console.error('update error', resp)
    })
  }

  @computed
  get allConversations () {
    return sortBy(this._conversations, c => -1 * (c.last_human_message_at || c.last_message_at).toMillis())
  }

  @computed
  get isLoading () {
    return this._conversationsLoading
  }

  get currentUserIsTechnician () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'technician')
  }

  get currentUserIsCsr () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'csr')
  }

  get currentUserIsAdmin () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'admin')
  }

  sendMessage (data) {
    return Messages.create(data)
      .then(msg => {
        return msg
      })
  }

  load () {
    if (this.isLoading) {
      return this._promise
    }

    this._promise = Promise.all([
      this.refreshConversations(),
    ]).then(() => {
      this._promise = null
    })

    return this._promise
  }

  searchByPhoneNumber (phoneNumber) {
    return Conversations.getByPhoneNumber(phoneNumber)
  }

  refreshConversations () {
    this._conversationsLoading = true
    return Conversations.getAll()
      .then(convos => {
        this._conversations = convos
        this._conversationsLoading = false
      })
  }
}

export default new ConversationsService()
