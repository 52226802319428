<template>
  <div class="border border-success-color p-0.5 md:p-1">
    <span class="text-sm md:text-base text-secondary">{{ label }}</span>
    <span class="text-base md:text-xl font-bold text-primary">{{ displayValue }}{{ unit }}</span>
  </div>
</template>

<script>

// ORIGINAL CODE
// <div
//   class="flex justify-center items-center space-x-3 px-2 bg-gray-100 w-full"
//   :style="`border: 1px solid ${borderColor}`"
// >
//   <div class="flex justify-start items-center space-x-1">
//     <!-- QR code -->
//     <div v-html="label"></div>
//     <span
//       class="text-gray-dark font-medium text-2xl uppercase tracking-tighter"
//       >Magic</span
//     >
//   </div>

//   <div>
//     <span class="text-3xl font-bold text-primary">{{ value }}</span>
//     <span class="text-gray-dark text-xl font-bold">/{{ goal }}</span>
//   </div>
// </div>

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    goal: {
      type: Number,
    },
    unit: {
      type: String,
    },
  },
  computed: {
    displayValue () {
      if (this.unit === '%') {
        return parseInt(this.value * 100)
      } else {
        return this.value
      }
    }
  },
}
</script>

<style></style>
