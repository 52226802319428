import { computed, observable } from 'mobx'
import { findIndex, groupBy, reject } from 'lodash-es'
import TechnicianAlerts from '@http/TechnicianAlerts'
import Users from '@http/Users'
import { WindowVariables } from '@utils'

class TechnicianAlertService {
  @observable _alerts = []
  @observable _technicians = []
  @observable _alertsLoading = false
  @observable _techniciansLoading = false

  _promise = null

  @computed
  get allAlerts () {
    return this._alerts
  }

  @computed
  get alertsByTechnicianId () {
    return groupBy(this.allAlerts, 'technician_id')
  }

  @computed
  get allTechnicians () {
    return this._technicians
  }

  @computed
  get alertsLoading () {
    return this._alertsLoading
  }

  @computed
  get techniciansLoading () {
    return this._techniciansLoading
  }

  @computed
  get isLoading () {
    return this.alertsLoading || this.techniciansLoading
  }

  @computed
  get canAcknowledgeAlerts () {
    
  }

  get currentUserIsTechnician () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'technician')
  }

  get currentUserIsCsr () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'csr')
  }

  get currentUserIsAdmin () {
    return !!WindowVariables.currentUserRoles.find(r => r.name === 'admin')
  }

  createAlert (params) {
    return TechnicianAlerts.create(params)
      .then(ta => {
        this._alerts = [...this._alerts, ta]
      })
  }

  removeAlert (id) {
    return TechnicianAlerts.destroy(id)
      .then(() => {
        this._alerts = reject(this._alerts, { id })
      })
  }

  acknowledge (id) {
    return TechnicianAlerts.acknowledge(id)
      .then(ta => {
        const tempArr = this._alerts
        const index = findIndex(tempArr, { id })
        tempArr.splice(index, 1, ta);
        this._alerts = tempArr
      })
  }

  load () {
    if (this.isLoading) {
      return this._promise
    }

    this._promise = Promise.all([
      this.refreshAlerts(),
      this.refreshTechnicians(),
    ]).then(() => {
      this._promise = null
    })

    return this._promise
  }

  refreshAlerts () {
    this._alertsLoading = true
    return TechnicianAlerts.getAll()
      .then(tas => {
        this._alerts = tas
        this._alertsLoading = false
      })
  }

  refreshTechnicians () {
    this._techniciansLoading = true
    if (this.currentUserIsTechnician) {
      return Users.getById(WindowVariables.currentUser.id)
        .then(tech => {
          this._technicians = [tech]
          this._techniciansLoading = false
        })
    } else {
      return Users.getAll({ role: 'technician' })
        .then(techs => {
          this._technicians = techs
          this._techniciansLoading = false
        })
    }
  }
}

export default new TechnicianAlertService()
