import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

class Notify {
  success () {
    return toastr.success(...arguments)
  }

  error () {
    return toastr.error(...arguments)
  }
}

export default new Notify()