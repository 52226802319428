<template>
  <div class="technician-alert">
    <li class="py-3">
      <div class="flex space-x-3">
        <!-- <img class="h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" alt=""> -->
        <div class="flex-1 space-y-1 flex justify-between">

          <div class="flex items-center mr-3">
            <p class="text-center text-lg mr-4" :class="upcomingTextClass" style="min-width:125px;">{{ timecode }}</p>
            <div class="flex flex-col">
              <h3 class="text-sm font-medium" :class="upcomingTextClass">{{ text }}</h3>
              <p class="text-sm text-gray-500" :class="upcomingTextClass">Created by {{ alert.posted_by_user.fullname }}</p>
            </div>
          </div>

          <div class="flex items-center">
            <p class="text-sm text-gray-500 mr-4" :class="upcomingTextClass" v-if="hasBeenPosted">{{ acknowledged }}</p>
            <div class="action-buttons max-width">
              <button v-if="canAcknowledge" class="btn btn-primary" @click="acknowledge">Acknowledge</button>
              <button v-if="canRemove" class="btn btn-danger" @click="remove">Remove</button>
            </div>
          </div>
          
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import TechnicianAlertService from '@services/TechnicianAlertService'
import { WindowVariables } from '@utils'

export default {
  name: 'technician-alert',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      removing: false,
    }
  },
  computed: {
    upcomingTextClass () {
      return !this.hasBeenPosted && 'text-gray-500'
    },
    timecode () {
      const alertFormatted = this.alert.posted_at.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      const todayFormatted = DateTime.local().toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      const tomorrowFormatted = DateTime.local().plus({ day: 1 }).toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      const yesterdayFormatted = DateTime.local().minus({ day: 1 }).toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      if (alertFormatted === todayFormatted) {
        return 'Today'
      } else if (alertFormatted === tomorrowFormatted) {
        return 'Tomorrow'
      } else if (alertFormatted === yesterdayFormatted) {
        return 'Yesterday'
      } else {
        return alertFormatted
      }
    },
    text () {
      return this.alert.text
    },
    acknowledged () {
      if (!this.alert.acknowledged_at && !TechnicianAlertService.currentUserIsTechnician) {
        return `Not acknowledged`
      } else if (this.alert.acknowledged_at) {
        return `Acknowledged: ${this.alert.acknowledged_at.toLocaleString(DateTime.DATETIME_SHORT)}`
      } else {
        return ''
      }
    },
    hasBeenAcknowledged () {
      return !!this.alert.acknowledged_at
    },
    hasBeenPosted () {
      return this.alert.posted_at <= new Date()
    },
    canAcknowledge () {
      return this.hasBeenPosted && !this.hasBeenAcknowledged && TechnicianAlertService.currentUserIsTechnician && this.alert.technician_id === WindowVariables.currentUser.id
    },
    canRemove () {
      return !this.hasBeenAcknowledged && (TechnicianAlertService.currentUserIsCsr || TechnicianAlertService.currentUserIsAdmin)
    },
  },
  methods: {
    remove () {
      this.removing = true
      TechnicianAlertService.removeAlert(this.alert.id)
    },
    acknowledge () {
      this.acknowledging = true
      TechnicianAlertService.acknowledge(this.alert.id)
    },
  },
}
</script>

<style>

</style>
